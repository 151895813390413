import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
import * as signatoryWebhookExample from '../../../examples/signatoryWebhook.graphql';
export const _frontmatter = {
  "product": "signatures",
  "category": "Webhooks",
  "sort": 1,
  "title": "Events"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Webhooks will be executed as `}<inlineCode parentName="p">{`POST`}</inlineCode>{` requests against the URL you have defined on your signature order. The URL will be used as-is, no parameters will be stripped.`}</p>
    <p>{`The POST request will contain a `}<inlineCode parentName="p">{`JSON`}</inlineCode>{` body similar to the events shown below.`}</p>
    <p>{`You can use the values included in the webhook body to query the GraphQL API for more details.`}</p>
    <h2>{`SIGNATORY_SIGNED`}</h2>
    <p>{`Triggered when a signatory successfully signs the order.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_SIGNED",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]"
}
`}</code></pre>
    <h2>{`SIGNATORY_REJECTED`}</h2>
    <p>{`Triggered when a signatory rejects the signature order via the UI.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_REJECTED",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]",
  "reason" = "[REASON]" | null
}
`}</code></pre>
    <h2>{`SIGNATORY_SIGN_ERROR`}</h2>
    <p>{`Triggered when a signatory experiences an error as part of signing.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_SIGN_ERROR",
  "error" = "[ERROR MESSAGE]",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]"
}
`}</code></pre>
    <h2>{`SIGNATORY_SIGN_LINK_OPENED`}</h2>
    <p>{`Triggered when a signatory opens the sign link `}<inlineCode parentName="p">{`href`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_SIGN_LINK_OPENED",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]"
}
`}</code></pre>
    <h2>{`SIGNATORY_DOWNLOAD_LINK_OPENED`}</h2>
    <p>{`Triggered when a signatory opens the download link `}<inlineCode parentName="p">{`downloadHref`}</inlineCode></p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_DOWNLOAD_LINK_OPENED",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]"
}
`}</code></pre>
    <h2>{`SIGNATORY_DOCUMENT_STATUS_CHANGED`}</h2>
    <p>{`Triggered when a signatory opens, approves or rejects a document.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATORY_DOWNLOAD_LINK_OPENED",
  "signatureOrderId" = "[GraphQL ID]",
  "signatoryId" = "[GraphQL ID]",
  "documentId" = "[GraphQL ID]",
  "status" = "OPENED|APPROVED|REJECTED|%future%"
}
`}</code></pre>
    <h2>{`SIGNATURE_ORDER_EXPIRED`}</h2>
    <p>{`Triggered when a signature order has expired and is cleaned up.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "SIGNATURE_ORDER_EXPIRED",
  "signatureOrderId" = "[GraphQL ID]"
}
`}</code></pre>
    <h2>{`WEBHOOK_VALIDATION`}</h2>
    <p>{`Triggered when a signature order is created with webhook validation.`}</p>
    <p>{`Make sure to respond with `}<inlineCode parentName="p">{`200`}</inlineCode>{`/`}<inlineCode parentName="p">{`OK`}</inlineCode>{` our your signature order will not be created.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "event" = "WEBHOOK_VALIDATION"
}
`}</code></pre>
    <h2>{`Query example`}</h2>

    <CodeBlock text={signatoryWebhookExample.query.trim()} mdxType="CodeBlock" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      